import {
    PopupComponent as SourcePopupComponent,
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace AlmusbahblendPwa/Component/Popup/Component */
export class PopupComponent extends SourcePopupComponent {
    // TODO implement logic

    // Same with click outside
    handleClickOutside() {
        const { isCloseOnOutsideClick, isMobile } = this.props;
        return;

        if (!isCloseOnOutsideClick) {
            return;
        }

        if (isMobile) {
            return;
        }

        this.hidePopupAndGoBack();
    }
}

export default PopupComponent;
