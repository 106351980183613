import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer,
} from 'SourceComponent/Popup/Popup.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
