import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    MyAccountAddressFormContainer as SourceMyAccountAddressFormContainer,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.container';

import {
    transformCountriesToOptions,
} from 'Util/Address';

import {
    getSACitiesList as getAvailableCities,
} from 'Util/Address/CityList';

export const mapStateToProps = (state) => ({
    countries: transformCountriesToOptions(state.ConfigReducer.countries || []),
    defaultCountry: state.ConfigReducer.default_country,
    addressLinesQty: state.ConfigReducer.address_lines_quantity,
    showVatNumber: state.ConfigReducer.show_vat_number_on_storefront,
    regionDisplayAll: state.ConfigReducer.region_display_all,
    currentStore: state.ConfigReducer.code,
});

export {
    mapDispatchToProps,
    getAvailableCities,
};

/** @namespace AlmusbahblendPwa/Component/MyAccountAddressForm/Container */
export class MyAccountAddressFormContainer extends SourceMyAccountAddressFormContainer {
    // TODO implement logic
    containerFunctions = {
        onCountryChange: this.onCountryChange.bind(this),
        onZipcodeChange: this.onZipcodeChange.bind(this),
        onCityChange: this.onCityChange.bind(this),
        onRegionChange: this.onRegionChange.bind(this),
        onRegionIdChange: this.onRegionIdChange.bind(this),
        onTelephoneChange: this.onTelephoneChange.bind(this),
    };

    __construct(props) {
        super.__construct?.(props);

        this.state = {
            countryId: this.getCountry()?.value || 'SA',
            availableRegions: this.getAvailableRegions() || [],
            availableCities: [],
            isStateRequired: !!this.getCountry()?.is_state_required,
            currentCity: this.getCurrentAddress().city,
            currentRegion: this.getCurrentAddress().region,
            currentZipcode: this.getCurrentAddress().postcode,
            currentRegionId: this.getCurrentAddress().regionId,
            currentTelephone: this.getCurrentAddress().telephone || '',
            currentTeleExtCode: this.getCurrentAddress().tele_ext_code,
        };
    }

    async componentDidMount() {
        await this.getAvailableCities();
    }

    containerProps() {
        const {
            address,
            countries,
            defaultCountry,
            addressLinesQty,
            showVatNumber,
            regionDisplayAll,
            onSave,
        } = this.props;
        const {
            countryId,
            availableRegions,
            availableCities,
            isStateRequired,
            currentCity,
            currentRegion,
            currentZipcode,
            currentRegionId,
            currentTelephone,
            currentTeleExtCode,
        } = this.state;

        // const lastCities = await this.getAvailableCities();

        return {
            address,
            countries,
            defaultCountry,
            addressLinesQty,
            showVatNumber,
            regionDisplayAll,
            countryId,
            availableRegions: Array.isArray(availableRegions) ? availableRegions : [],
            availableCities,
            isStateRequired,
            onSave,
            currentCity,
            currentRegion,
            currentZipcode,
            currentRegionId,
            currentTelephone,
            currentTeleExtCode,
        };
    }

    getCurrentAddress() {
        const { address, address: { id: addressId } = {} } = this.props;

        if (!addressId) {
            return {
                region: '',
                regionId: 1,
                postcode: '',
                city: '',
                telephone: '',
            };
        }

        const { region: { region, region_id: regionId }, postcode, city, telephone = '' } = address;

        return {
            region,
            regionId,
            postcode,
            city,
            telephone,
        };
    }

    /**
     * Returns available cities based on country
     * @param countryId
     * @returns {Promise<[*, *]|null[]|*>}
     */
    async getAvailableCities(countryId = 'SA') {
        const { countries, defaultCountry, currentStore } = this.props;
        const { value: currCountryId = defaultCountry } = this.getCountry(countryId) || {};
        // console.log("currentStore : ", currentStore);
       await getAvailableCities(currCountryId, currentStore).then((gotCities) => {
            // console.log("SA Cities: ", gotCities);
            this.setState({availableCities: gotCities});
            return gotCities;
       });
        
    }
    // #endcity

    // #Telegpne number change
    onTelephoneChange(field) {
        this.setState({ currentTelephone: field.target.value });
    }

    // #region EVENTS
    onCityChange(field, e) {
        
        const fieldValue = typeof field === 'object' ? e && e.value : field;
        // console.log("City Field: ", field);
        // console.log("City e: ", e);
        // console.log("City fieldValue: ", fieldValue);
        this.setState({ currentCity: fieldValue });
    }


    async onCountryChange(field, e) {
        // Handles auto fill
        const fieldValue = typeof field === 'object' ? e && e.value : field;

        const { currentZipcode } = this.state;
        const { countries } = this.props;
        const country = countries.find(({ value }) => value === fieldValue);

        if (!country) {
            this.setState({
                currentRegion: '',
                currentRegionId: 1,
                currentTelephone: '',
                countryId: '',
                availableRegions: [],
                availableCities:[],
            });

            return;
        }

        const {
            available_regions: availableRegions = [],
            is_state_required: isStateRequired = true,
            value: countryId,
        } = country;

        this.getAvailableRegions(countryId, currentZipcode);
        await this.getAvailableCities(countryId);
        this.setState({
            availableRegions,
            isStateRequired: isStateRequired || false,
            countryId,
            currentRegionId: 1,
            currentRegion: '',
            currentTelephone: '',
        });
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    MyAccountAddressFormContainer,
);
