import {
    FieldContainer as SourceFieldContainer,
} from 'SourceComponent/Field/Field.container';

import { FieldFileContainerProps, FieldFileContainerState } from 'Component/FieldFile/FieldFile.type';
import { ReactElement } from 'Type/Common.type';
import { ValidationOutput } from 'Type/Field.type';
import { noopFn } from 'Util/Common';
import { validate } from 'Util/Validator';
import { FieldValidationOutput, ValidationRule } from 'Util/Validator/Validator.type';

import { FieldType } from 'SourceComponent/Field/Field.config';

/** @namespace AlmusbahblendPwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    // TODO implement logic
    state = {
        validationResponse: null,
        showLengthError: false,
        cityValue: null,
    };

    containerFunctions = {
        validate: this.validate.bind(this),
        setCityValue: this.setCityValue.bind(this),
    };

    containerProps() {
        const {
            events,
            validateOn,
            type,
            attr,
            isDisabled,
            mix,
            value,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
        } = this.props;
        const { validationResponse, cityValue } = this.state;
        const { validate } = this.containerFunctions;

        // Surrounds events with validation
        const newEvents = { ...events };

        validateOn.forEach((eventName) => {
            const { [ eventName ]: baseEvent } = events;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newEvents[ eventName ] = baseEvent
                ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            attr,
            value,
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            validationResponse,
            resetFieldValue: this.resetFieldValue.bind(this),
            events: newEvents,
            setRef: this.setRef.bind(this),
        };
    }

    setCityValue(cityValue) {
        this.setState({cityValue: cityValue});
    }

    validate(data) {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;

        const {
            cityValue
        } = this.state;

        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        let value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef)?.checked
            : this.fieldRef?.value;

        if(name == "city") {
            value = cityValue;
        }

        // console.log("validate value: ", value);
        if (!value && value !== '') {
            return false;
        }

        const response = validate(type === FieldType.FILE
            && typeof value === 'string'
            ? value.toLowerCase()
            : value, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }
        // console.log("validate output: ", output);
        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;
