import { PureComponent, createRef, useRef } from 'react';
import {
    FieldSelectComponent as SourceFieldSelectComponent,
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { FieldReactEvents } from 'Component/Field/Field.type';
import { ReactElement } from 'Type/Common.type';
import { Option } from 'Type/Field.type';
import { noopFn } from 'Util/Common';
import Select, { SelectInstance } from 'react-select';
import './FieldSelect.override.style';

/** @namespace AlmusbahblendPwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelectComponent {
    // TODO implement logic
    // asyncRef = useRef(null);

    // _selectRef = useRef();

//   constructor(props){
//     super(props);
//     this.selectRef = React.createRef();
//   }

    // componentDidMount() {
    //     const {
    //         setRef
    //     } = this.props;

    //     const elem = document.getElementById("city");
    //     if(elem) {
    //         console.log("City Elm:", elem );
    //         setRef(elem);
    //     }
    // }

    render() {
        const {
            attr,
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
            options,
            events,
            handleSelectListOptionClick,
            handleSelectListOptionClickBind,
            isSelectedOptionAvailable,
            setRef,
        } = this.props;

        if (attr.name == 'city') {
            return (
                <ClickOutside onClick={ handleSelectExpandedExpand }>
                    <div
                    id={ `${ id }_wrapper` }
                    block="FieldSelect"
                    mods={ { isExpanded } }
                    onClick={ !isDisabled ? handleSelectExpand : noopFn }
                    onKeyPress={ !isDisabled ? handleSelectListKeyPress : noopFn }
                    role="button"
                    tabIndex={ 0 }
                    aria-label="Select dropdown"
                    aria-expanded={ isExpanded }
                    >
                        <Select 
                            mods={ { isDisabled: !isSelectedOptionAvailable } }
                            // ref={ () => setRef(this._selectRef.current.select()) }
                            disabled={ isDisabled }
                            options={options}
                            defaultValue={attr.value}
                            id={attr.name}
                            name={attr.name}
                            defaultInputValue={attr.value}
                            className={"Searchable_Select"}
                            placeholder={__("Select City")}
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            // { ...attr }
                            // value={attr.value}
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            // { ...events }
                            onChange={ handleSelectListOptionClickBind }
                        />
                    </div>
                </ClickOutside>
            );
        }

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ !isDisabled ? handleSelectExpand : noopFn }
                  onKeyPress={ !isDisabled ? handleSelectListKeyPress : noopFn }
                  role="button"
                  tabIndex={ 0 }
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? Directions.TOP : Directions.BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
