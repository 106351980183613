import {
    RegionQuery as SourceRegionQuery,
} from 'SourceQuery/Region.query';

import { Field, Mutation, Query } from '@tilework/opus';

/** @namespace AlmusbahblendPwa/Query/ */
export class RegionQuery extends SourceRegionQuery {
    // TODO implement logic

    getCountryCityList(country_id = 'SA', store_code = 'en-sa') {
        const orderField = new Field('getCountryCityList');
        return orderField
            .addArgument('country_id', 'String', country_id)
            .addArgument('store_code', 'String', store_code)
            .addFieldList(this._getCityListDataFields())

    }

    _getCityListDataFields() {
        return [
            'country_id',
            this._getCityData(),
        ];
    }

    _getCityData() {
        return new Field('country_cities', true)
            .addFieldList(this._getCityDataFileds());
    }

    _getCityDataFileds() {
        return [
            'id',
            'name',
            'name_ar',
        ]
    }
}

export default new RegionQuery();
