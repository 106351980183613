import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionContainer as SourceCheckoutDeliveryOptionContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/CheckoutDeliveryOption/Container */
export class CheckoutDeliveryOptionContainer extends SourceCheckoutDeliveryOptionContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionContainer);
