import {
    FieldComponent as SourceFieldComponent,
} from 'SourceComponent/Field/Field.component';

import FieldFile from 'Component/FieldFile';
import { FieldNumberWithControlsContainer } from 'Component/FieldNumberWithControls/FieldNumberWithControls.container';
import FieldSelectContainer from 'Component/FieldSelect';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import './Field.override.style';

/** @namespace AlmusbahblendPwa/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    // TODO implement logic

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            setCityValue,
        } = this.props;

        return (
            <FieldSelectContainer
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isSortSelect={ isSortSelect }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
              setCityValue={ setCityValue }
            />
        );
    }
}

export default FieldComponent;
