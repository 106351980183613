import {
    FieldSelectContainer as SourceFieldSelectContainer,
} from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace AlmusbahblendPwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    // TODO implement logic

    containerFunctions = {
        handleSelectExpand: this.handleSelectExpand.bind(this),
        handleSelectExpandedExpand: this.handleSelectExpandedExpand.bind(this),
        handleSelectListOptionClick: this.handleSelectListOptionClick.bind(this),
        handleSelectListOptionClickBind: this.handleSelectListOptionClickBind.bind(this),
        handleSelectListKeyPress: this.handleSelectListKeyPress.bind(this),
        setRef: this.setRef.bind(this),
        handleIsScrollableList: this.handleIsScrollableList.bind(this),
        handleDropdownOpenDirection: this.handleDropdownOpenDirection.bind(this),
    };

    setRef(elem) {
        const { setRef } = this.props;

        setRef(elem);

        if (elem && this.fieldRef !== elem) {
            this.fieldRef = elem;
        }
    }

    handleSelectListOptionClickBind(option) {
        // console.log("handleSelectListOptionClick: option: ", option);
        const { changeValueOnDoubleClick, events: { onChange } = {}, setCityValue } = this.props;
        const { value, target: { value: targetValue } = {} } = option;
        const fieldValue = value || targetValue || '';
        
        // console.log("handleSelectListOptionClick: fieldValue: ", fieldValue);
        setCityValue(fieldValue);
        // if (!this.fieldRef) {
        //     this.fieldRef = <select name='city' id='city'>
        //         <option></option>
        //     </select>;
        // }
        // console.log("handleSelectListOptionClick: this.fieldRef: ", this.fieldRef);
        
        // if (changeValueOnDoubleClick) {
        //     this.fieldRef.value = this.fieldRef.value === value ? '' : String(fieldValue);
        // } else {
            // this.fieldRef.value = String(fieldValue);
        // }

        if (onChange) {
            onChange(String(fieldValue));
        }

    }

    getOptions() {
        const {
            options,
            attr: {
                id = 'select',
                name,
                selectPlaceholder = __('Select item...'),
                noPlaceholder,
            } = {},
        } = this.props;

        if (noPlaceholder || name == "city") {
            return options;
        }

        return [
            {
                id: `${id}-placeholder`,
                name: `${id}-placeholder`,
                label: selectPlaceholder,
                value: '',
                sort_order: -100,
                isPlaceholder: true,
            },
            ...options,
        ];
    }
}

export default FieldSelectContainer;
