import {
    CheckoutDeliveryOptionComponent as SourceCheckoutDeliveryOptionComponent,
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { ReactElement } from 'Type/Common.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { formatPrice, formatPriceFilter } from 'Util/Price';

import './CheckoutDeliveryOption.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutDeliveryOption/Component */
export class CheckoutDeliveryOptionComponent extends SourceCheckoutDeliveryOptionComponent {
    // TODO implement logic

    getOptionPrice() {
        const {
            currency,
            optionPrice,
        } = this.props;

        return formatPriceFilter("space", optionPrice, currency);
    }

    renderPrice() {
        const {
            currency,
            optionPrice,
            option: {
                available,
            } = {},
        } = this.props;

        if (!available) {
            return null;
        }

        return (
            <strong>
                { this.getOptionPrice() }
            </strong>
        );
    }
}

export default CheckoutDeliveryOptionComponent;
