/**
 * @author - Sanyasi Rao
 */

import { CustomerAddress } from 'Query/MyAccount.type';
import { OrderAddress } from 'Query/Order.type';
import { Country, Region } from 'Query/Region.type';
import RegionQuery from 'Query/Region.query';
import { Store } from 'Query/StoreInPickUp.type';
import { CheckoutAddress } from 'Route/Checkout/Checkout.type';
import { GQLCountryCodeEnum, GQLCustomerAddressInput } from 'Type/Graphql.type';
import { getStoreState } from 'Util/Store';
import { fetchQuery } from 'Util/Request/Query';

import { CountryOption, FormattedRegion, ZippopotamResponseResult } from './Address.type';


/** @namespace Util/Address/Index/getAvailableRegions */
export const getSACitiesList = async (country_id, store_code = 'en-sa') => {
    let cities = [];
    if (country_id == 'SA') {
        cities = await getSACities(store_code);
    }

    // const country = countries.find(({ id }) => id === country_id);
    // const { available_regions } = country || {};
    // console.log("enCities: ", cities);
    // need to handle null value
    return cities;
};

export const getCountryCityList = async (country_id, store_code) => {
    const cityListData = await fetchQuery(
        RegionQuery.getCountryCityList(country_id, store_code), 
    );

    const cityData = cityListData.getCountryCityList.country_cities;

    return cityData;

}

export const getSACities = async (store_code) => {
    const cityData = await getCountryCityList("SA", store_code);
    const enCities = [];
    const arCities = [];

    cityData.map((city) => {
        enCities.push({ name: city.name});
        arCities.push({ name: city.name_ar});
    });

    if (store_code == 'ar-sa') {
        return arCities;
    } else {
        return enCities;
    }

    // console.log("enCities: ", enCities);
    // console.log("arCities: ", arCities);


    // const enCities = [
    //     {name:__("Aba Alworood")},
    //     {name:__("Abha")},
    //     {name:__("Abha Manhal")},
    //     {name:__("Abqaiq")},
    //     {name:__("Abu Ajram")},
    //     {name:__("Abu Areish")},
    //     {name:__("Ad Dahinah")},
    //     {name:__("Ad Dubaiyah")},
    //     {name:__("Addayer")},
    //     {name:__("Adham")},
    //     {name:__("Afif")},
    //     {name:__("Aflaj")},
    //     {name:__("Ahad Masarha")},
    //     {name:__("Ahad Rufaidah")},
    //     {name:__("Ain Dar")},
    //     {name:__("Al Adari")},
    //     {name:__("Al Ais")},
    //     {name:__("Al Ajfar")},
    //     {name:__("Al Ammarah")},
    //     {name:__("Al Ardah")},
    //     {name:__("Al Arja")},
    //     {name:__("Al Asyah")},
    //     {name:__("Al Bada")},
    //     {name:__("Al Baha")},
    //     {name:__("Al Bashayer")},
    //     {name:__("Al Batra")},
    //     {name:__("Al Bijadyah")},
    //     {name:__("Al Dalemya")},
    //     {name:__("Al Fuwaileq / Ar Rishawiyah")},
    //     {name:__("Al Haith")},
    //     {name:__("Al Hassa")},
    //     {name:__("Al Hayathem")},
    //     {name:__("Al Hufayyirah")},
    //     {name:__("Al Hulayfah As Sufla ")},
    //     {name:__("Al Idabi")},
    //     {name:__("Al Jouf")},
    //     {name:__("Al Khishaybi")},
    //     {name:__("Al Khitah")},
    //     {name:__("Al Laqayit")},
    //     {name:__("Al Mada")},
    //     {name:__("Al Mahani")},
    //     {name:__("Al Mahd")},
    //     {name:__("Al Midrij")},
    //     {name:__("Al Moya")},
    //     {name:__("Al Qarin")},
    //     {name:__("Al Uwayqilah")},
    //     {name:__("Al Wasayta")},
    //     {name:__("Al-Jsh")},
    //     {name:__("Alghat")},
    //     {name:__("Alhada")},
    //     {name:__("Alnabhanya")},
    //     {name:__("AlRass")},
    //     {name:__("Amaq")},
    //     {name:__("An Nabk Abu Qasr")},
    //     {name:__("An Nafiah")},
    //     {name:__("An Nuqrah")},
    //     {name:__("Anak")},
    //     {name:__("Aqiq")},
    //     {name:__("Aqool ")},
    //     {name:__("Ar Radifah")},
    //     {name:__("Ar Rafi'ah")},
    //     {name:__("Arar")},
    //     {name:__("Artawiah")},
    //     {name:__("As Sulaimaniyah")},
    //     {name:__("As Sulubiayh")},
    //     {name:__("Ash Shaara")},
    //     {name:__("Ash Shamli")},
    //     {name:__("Ash Shananah")},
    //     {name:__("Ash Shimasiyah")},
    //     {name:__("Ash Shuqaiq")},
    //     {name:__("Asheirah")},
    //     {name:__("At Tuwayr")},
    //     {name:__("Atawleh")},
    //     {name:__("Ath Thybiyah")},
    //     {name:__("Awamiah")},
    //     {name:__("Ayn Fuhayd")},
    //     {name:__("Badaya")},
    //     {name:__("Bader")},
    //     {name:__("Badr Al Janoub")},
    //     {name:__("Bahr Abu Sukaynah ")},
    //     {name:__("Balahmar")},
    //     {name:__("Balasmar")},
    //     {name:__("Balqarn")},
    //     {name:__("Baqa Ash Sharqiyah")},
    //     {name:__("Baqaa")},
    //     {name:__("Baqiq")},
    //     {name:__("Bareq")},
    //     {name:__("Batha")},
    //     {name:__("BilJurashi")},
    //     {name:__("Birk")},
    //     {name:__("Bish")},
    //     {name:__("Bisha")},
    //     {name:__("Bukeiriah")},
    //     {name:__("Buraidah")},
    //     {name:__("Daelim")},
    //     {name:__("Damad")},
    //     {name:__("Dammam")},
    //     {name:__("Darb")},
    //     {name:__("Dariyah")},
    //     {name:__("Dawadmi")},
    //     {name:__("Deraab")},
    //     {name:__("Dere'iyeh")},
    //     {name:__("Dhahran")},
    //     {name:__("Dhahran Al Janoob")},
    //     {name:__("Dhurma")},
    //     {name:__("Domat Al Jandal")},
    //     {name:__("Duba")},
    //     {name:__("Duhknah")},
    //     {name:__("Dulay Rashid")},
    //     {name:__("Farasan")},
    //     {name:__("Ghazalah")},
    //     {name:__("Ghtai")},
    //     {name:__("Gilwa")},
    //     {name:__("Gizan")},
    //     {name:__("Hadeethah")},
    //     {name:__("Hafer Al Batin")},
    //     {name:__("Hail")},
    //     {name:__("Hajrah")},
    //     {name:__("Halat Ammar")},
    //     {name:__("Hali ")},
    //     {name:__("Haqil")},
    //     {name:__("Harad")},
    //     {name:__("Harajah")},
    //     {name:__("Hareeq")},
    //     {name:__("Hawea/Taif")},
    //     {name:__("Haweyah/Dha")},
    //     {name:__("Hawtat Bani Tamim")},
    //     {name:__("Hazm Al Jalamid")},
    //     {name:__("Hedeb")},
    //     {name:__("Hinakeya")},
    //     {name:__("Hofuf")},
    //     {name:__("Horaimal")},
    //     {name:__("Hotat Sudair")},
    //     {name:__("Hubuna")},
    //     {name:__("Huraymala")},
    //     {name:__("Huroob ")},
    //     {name:__("Ja'araneh")},
    //     {name:__("Jafar")},
    //     {name:__("Jalajel")},
    //     {name:__("Jeddah")},
    //     {name:__("Jubail")},
    //     {name:__("Jumum")},
    //     {name:__("Kahlah")},
    //     {name:__("Kara")},
    //     {name:__("Kara'a")},
    //     {name:__("Karboos")},
    //     {name:__("Khafji")},
    //     {name:__("Khaibar")},
    //     {name:__("Khairan")},
    //     {name:__("Khamaseen")},
    //     {name:__("Khamis Mushait")},
    //     {name:__("Kharj")},
    //     {name:__("Khasawyah")},
    //     {name:__("Khobar")},
    //     {name:__("Khodaria")},
    //     {name:__("Khurma")},
    //     {name:__("King Khalid Military City")},
    //     {name:__("Kubadah")},
    //     {name:__("Laith")},
    //     {name:__("Layla")},
    //     {name:__("Madinah")},
    //     {name:__("Mahad Al Dahab")},
    //     {name:__("Majarda")},
    //     {name:__("Majma")},
    //     {name:__("Makkah")},
    //     {name:__("Mandak")},
    //     {name:__("Mastura")},
    //     {name:__("Mawqaq")},
    //     {name:__("Midinhab")},
    //     {name:__("Mikhwa")},
    //     {name:__("Mohayel Aseer")},
    //     {name:__("Moqaq")},
    //     {name:__("Mrat")},
    //     {name:__("Mubaraz")},
    //     {name:__("Mubayid")},
    //     {name:__("Mulaija")},
    //     {name:__("Mulayh")},
    //     {name:__("Munifat Al Qaid")},
    //     {name:__("Muthaleif")},
    //     {name:__("Muzahmiah")},
    //     {name:__("Muzneb")},
    //     {name:__("Nabiya")},
    //     {name:__("Najran")},
    //     {name:__("Namas")},
    //     {name:__("New Muwayh ")},
    //     {name:__("Nimra")},
    //     {name:__("Nisab")},
    //     {name:__("Noweirieh")},
    //     {name:__("Ojam")},
    //     {name:__("Onaiza")},
    //     {name:__("Othmanyah")},
    //     {name:__("Oula")},
    //     {name:__("Oyaynah")},
    //     {name:__("Oyoon Al Jawa")},
    //     {name:__("Qahmah")},
    //     {name:__("Qana ")},
    //     {name:__("Qarah")},
    //     {name:__("Qariya Al Olaya")},
    //     {name:__("Qasab")},
    //     {name:__("Qassim")},
    //     {name:__("Qatif")},
    //     {name:__("Qaysoomah")},
    //     {name:__("Qbah")},
    //     {name:__("Qouz")},
    //     {name:__("Qufar")},
    //     {name:__("Qunfudah")},
    //     {name:__("Qurayat")},
    //     {name:__("Qusayba")},
    //     {name:__("Quwei'ieh")},
    //     {name:__("Rabigh")},
    //     {name:__("Rafha")},
    //     {name:__("Rahima")},
    //     {name:__("Rania")},
    //     {name:__("Ras Al Kheir")},
    //     {name:__("Ras Baridi")},
    //     {name:__("Ras Tanura")},
    //     {name:__("Rawdat Habbas")},
    //     {name:__("Rejal Alma'a")},
    //     {name:__("Remah")},
    //     {name:__("Riyadh")},
    //     {name:__("Riyadh Al Khabra")},
    //     {name:__("Rowdat Sodair")},
    //     {name:__("Rvaya Aljamsh")},
    //     {name:__("Rwaydah")},
    //     {name:__("Sabt El Alaya")},
    //     {name:__("Sabya")},
    //     {name:__("Sadal Malik")},
    //     {name:__("Sadyan")},
    //     {name:__("Safanyah")},
    //     {name:__("Safwa")},
    //     {name:__("Sahna")},
    //     {name:__("Sajir")},
    //     {name:__("Sakaka")},
    //     {name:__("Salbookh")},
    //     {name:__("Salwa")},
    //     {name:__("Samakh")},
    //     {name:__("Samtah")},
    //     {name:__("Saqf")},
    //     {name:__("Sarar")},
    //     {name:__("Sarat Obeida")},
    //     {name:__("Satorp (Jubail Ind'l 2)")},
    //     {name:__("Seihat")},
    //     {name:__("Shaqra")},
    //     {name:__("Shari")},
    //     {name:__("Sharourah")},
    //     {name:__("Shefa'a")},
    //     {name:__("Shinanh")},
    //     {name:__("Shraie'e")},
    //     {name:__("Shumeisi")},
    //     {name:__("Siir")},
    //     {name:__("Simira")},
    //     {name:__("Subheka")},
    //     {name:__("Sulaiyl")},
    //     {name:__("Suwayr")},
    //     {name:__("Tablah")},
    //     {name:__("Tabrjal")},
    //     {name:__("Tabuk")},
    //     {name:__("Taif")},
    //     {name:__("Tanda")},
    //     {name:__("Tanjeeb")},
    //     {name:__("Tanuma")},
    //     {name:__("Tanumah")},
    //     {name:__("Tarut")},
    //     {name:__("Tatleeth")},
    //     {name:__("Tayma")},
    //     {name:__("Tebrak")},
    //     {name:__("Thabya")},
    //     {name:__("Thadek")},
    //     {name:__("Tharmada")},
    //     {name:__("Thebea")},
    //     {name:__("Thumair")},
    //     {name:__("Thuqba")},
    //     {name:__("Turaib")},
    //     {name:__("Turaif")},
    //     {name:__("Turba")},
    //     {name:__("Tuwaim")},
    //     {name:__("Udhaliyah")},
    //     {name:__("Um Aljamajim")},
    //     {name:__("Umluj")},
    //     {name:__("Uqlat Al Suqur")},
    //     {name:__("Ushayqir")},
    //     {name:__("Uyun")},
    //     {name:__("Wadeien")},
    //     {name:__("Wadi Bin Hasbal")},
    //     {name:__("Wadi El Dwaser")},
    //     {name:__("Wadi Faraah ")},
    //     {name:__("Wadi Fatmah")},
    //     {name:__("Wajeh (Al Wajh)")},
    //     {name:__("Waly AL Ahd ")},
    //     {name:__("Yadamah")},
    //     {name:__("Yanbu")},
    //     {name:__("Yanbu Al Baher")},
    //     {name:__("Yanbu Nakhil")},
    //     {name:__("Yuthma")},
    //     {name:__("Zallum")},
    //     {name:__("Zulfi")},
    //     {name: __("Others")}
    // ];

}

export default (getSACitiesList, getSACities);
