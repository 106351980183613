/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { NavigationTabsMap } from 'Component/NavigationTabs/NavigationTabs.config';
import { ProductType } from 'Component/Product/Product.config';
import CheckoutQuery from 'Query/Checkout.query';
import MyAccountQuery from 'Query/MyAccount.query';
import { CART_URL } from 'Route/CartPage/CartPage.config';
import { AccountPageUrl } from 'Route/MyAccount/MyAccount.config';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateShippingPrice, updateTotals } from 'Store/Cart/Cart.action';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { updateEmail, updateShippingFields } from 'Store/Checkout/Checkout.action';
import CheckoutDispatcher from 'Store/Checkout/Checkout.dispatcher';
import { updateMeta } from 'Store/Meta/Meta.action';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { setPickUpStore } from 'Store/StoreInPickUp/StoreInPickUp.action';
import { removeEmptyStreets } from 'Util/Address';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId, getCartTotalSubPrice } from 'Util/Cart';
import history from 'Util/History';
import { scrollToTop } from 'Util/Browser';
import scrollToError from 'Util/Form/Form';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import { debounce } from 'Util/Request/Debounce';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { appendWithStoreCode } from 'Util/Url';
import transformToNameValuePair from 'Util/Form/Transform';
import { showPopup } from 'Store/Popup/Popup.action';
import OnepageCheckout, { HYPERPAY_APPLEPAY, HYPERPAY_CARDS, HYPERPAY_MADA, TAMARA_PAYMENT, TABBY_PAYMENT } from './OnepageCheckout.component';
import { prepareQuery } from 'Util/Query';
import CartQuery from 'Query/Cart.query';
import { executePost } from 'Util/Request/Request';
import HyperpayQuery from '../../query/Hyperpay.query';
// import { RootState } from 'Util/Store/Store.type';
import { isEmpty, isNaN, has, isSet } from 'lodash';
import TamaraQuery from '../../query/Tamara.query';
import TabbyQuery from '../../query/Tabby.query';

import agtm from '../../gtm/tags';
import {
    CHECKOUT_URL_REGEX,
    CheckoutSteps,
    CheckoutStepUrl,
    CheckoutUrlSteps,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY,
    UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY,
} from './OnepageCheckout.config';
import {
    getFormFields,
    setAddressesInFormObject,
    trimCheckoutAddress,
    trimCheckoutCustomerAddress,
} from 'Util/Address';
import { PaymentMethods } from 'Component/CheckoutPayments/CheckoutPayments.config';
import {
    TERMS_AND_CONDITIONS_POPUP_ID,
} from 'Component/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.config';
import { StoreInPickUpCode } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { COD_PAYMENT } from 'Component/OnepageCheckoutBilling/OnepageCheckoutBilling.component';

export const CART_TOTALS = 'cart_totals';

/** @namespace Route/OnepageCheckout/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedStore: state.StoreInPickUpReducer.store,
    totals: state.CartReducer.cartTotals,
    isCartLoading: state.CartReducer.isLoading,
    cartTotalSubPrice: getCartTotalSubPrice(state),
    customer: state.MyAccountReducer.customer,
    guest_checkout: state.ConfigReducer.guest_checkout,
    countries: state.ConfigReducer.countries,
    isEmailAvailable: state.CheckoutReducer.isEmailAvailable,
    isMobile: state.ConfigReducer.device.isMobile,
    isInStoreActivated: state.ConfigReducer.delivery_instore_active,
    isGuestNotAllowDownloadable: state.ConfigReducer.downloadable_disable_guest_checkout,
    savedEmail: state.CheckoutReducer.email,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    shippingFields: state.CheckoutReducer.shippingFields,
    minimumOrderAmount: state.CartReducer.cartTotals.minimum_order_amount,
    currentStore: state.ConfigReducer.code,

    addressLinesQty: state.ConfigReducer.address_lines_quantity,
    savedShippingMethodCode: state.CheckoutReducer.shippingFields.shippingMethod,

    termsAreEnabled: state.ConfigReducer.terms_are_enabled,
    termsAndConditions: state.ConfigReducer.checkoutAgreements,
    newShippingId: state.CheckoutReducer.shippingFields.id,
    newShippingStreet: state.CheckoutReducer.shippingFields.street,
});

/** @namespace Route/Checkout/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setPickUpStore: (store) => dispatch(setPickUpStore(store)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    resetCart: (disableLoader) => CartDispatcher.updateInitialCartData(dispatch, !!getAuthorizationToken(), disableLoader),
    resetGuestCart: () => {
        CartDispatcher.resetGuestCart(dispatch);
        CartDispatcher.createGuestEmptyCart(dispatch);
    },
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state)),
    showErrorNotification: (message) => dispatch(showNotification(NotificationType.ERROR, message)),
    showInfoNotification: (message) => dispatch(showNotification(NotificationType.INFO, message)),
    showSuccessNotification: (message) => dispatch(showNotification(NotificationType.SUCCESS, message)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, stateName)),
    setNavigationState: (stateName) => dispatch(
        changeNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE, stateName),
    ),
    createAccount: (options) => MyAccountDispatcher.createAccount(options, dispatch),
    updateShippingFields: (fields) => dispatch(updateShippingFields(fields)),
    updateEmail: (email) => dispatch(updateEmail(email)),
    checkEmailAvailability: (email) => CheckoutDispatcher.handleData(dispatch, email),
    updateShippingPrice: (data) => dispatch(updateShippingPrice(data)),
    updateCartData: (data) => dispatch(updateTotals(data)),
    showPopup: (payload) => dispatch(showPopup(TERMS_AND_CONDITIONS_POPUP_ID, payload)),
});

/** @namespace Route/Checkout/Container */
export class OnepageCheckoutContainer extends PureComponent {
    static defaultProps = {
        cartTotalSubPrice: null,
        minimumOrderAmount: undefined,
        shippingFields: {},
        isSubmitted: false,
        newShippingId: 0,
        termsAreEnabled: false,
        isSignedIn: false,
    };

    static getDerivedStateFromProps(
        props,
        state,
    ) {
        const { paymentMethod = '', prevPaymentMethods = [] } = state;
        const { paymentMethods } = props;

        // if (!prevPaymentMethods.length && !paymentMethod) {
        //     const [method] = paymentMethods;
        //     const { code: paymentMethod } = method || {};

        //     return {
        //         prevPaymentMethods: paymentMethods,
        //         paymentMethod,
        //     };
        // }

        return null;
    }

    containerFunctions = {
        setLoading: this.setLoading.bind(this),
        setDetailsStep: this.setDetailsStep.bind(this),
        savePaymentInformation: this.savePaymentInformation.bind(this),
        saveAddressInformation: this.saveAddressInformation.bind(this),
        onShippingEstimationFieldsChange: this.onShippingEstimationFieldsChange.bind(this),
        onEmailChange: this.onEmailChange.bind(this),
        onCreateUserChange: this.onCreateUserChange.bind(this),
        onPasswordChange: this.onPasswordChange.bind(this),
        goBack: this.goBack.bind(this),
        handleSelectDeliveryMethod: this.handleSelectDeliveryMethod.bind(this),
        onStoreSelect: this.onStoreSelect.bind(this),
        onShippingMethodSelect: this.onShippingMethodSelect.bind(this),
        onChangeEmailRequired: this.onChangeEmailRequired.bind(this),
        onAddressSelect: this.onAddressSelect.bind(this),
        onSameAsShippingChange: this.onSameAsShippingChange.bind(this),
        onPaymentMethodSelect: this.onPaymentMethodSelect.bind(this),
        showPopup: this.showPopup.bind(this),
        onOnepageCheckoutPageSuccess: this.onOnepageCheckoutPageSuccess.bind(this),
        onOnepageCheckoutPageError: this.onOnepageCheckoutPageError.bind(this),
        onOnepageCheckoutProceedPayment: this.onOnepageCheckoutProceedPayment.bind(this),
        selectedDeliveryAddress: this.selectedDeliveryAddress.bind(this),
    };

    checkEmailAvailability = debounce((email) => {
        const { checkEmailAvailability } = this.props;

        checkEmailAvailability(email);
    }, UPDATE_EMAIL_CHECK_FREQUENCY);

    handleFetchEstimateShippingCosts = debounce(({ address, cartId }) => {
        const { requestsSent } = this.state;
        // console.log("handleFetchEstimateShippingCosts address: ", address);
        this.setState({
            isDeliveryOptionsLoading: true,
            requestsSent: requestsSent + 1,
            estimateAddress: address,
            selectedPaymentMethod: '',
            paymentMethod: '',
        });

        fetchMutation(CheckoutQuery.getEstimateShippingCosts(
            address,
            cartId,
        )).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/debounce/fetchMutation/then */
            ({ estimateShippingCosts: shippingMethods }) => {
                const { requestsSent } = this.state;

                this.setState({
                    shippingMethods,
                    isDeliveryOptionsLoading: requestsSent > 1,
                    requestsSent: requestsSent - 1,
                    isLoading: false,
                    // shippingAddress: address,
                    // billingAddress: address,
                });

                const [defaultShippingMethod] = shippingMethods.filter((method) => method.available);
                const selectedShippingMethod = defaultShippingMethod || {};
                const { method_code = '' } = selectedShippingMethod;
                // console.log("handleFetchEstimateShippingCosts selectedShippingMethod", selectedShippingMethod);
                this.setState( { selectedShippingMethod });
                // console.log("Before Calling save shipping address - shipping code: ", method_code);
                if (method_code) {
                    const {
                        carrier_code: shipping_carrier_code,
                        method_code: shipping_method_code,
                    } = selectedShippingMethod || {};
                    
                    const data = {
                        billing_address: address,
                        shipping_address: address,
                        shipping_carrier_code: shipping_carrier_code || '',
                        shipping_method_code: shipping_method_code || '',
                    };
                    // console.log("Calling save shipping address: ", data);
                    this.saveAddressInformation(data);
    
                    // this._getPaymentMethods();
                }
                
            },
            this._handleError,
        );
    }, UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY);

    __construct(props) {
        super.__construct?.(props);

        this._handleError = this._handleError.bind(this);

        const {
            toggleBreadcrumbs,
            totals,
            totals: {
                is_virtual,
            },
            savedEmail, 
            savedShippingMethodCode,
            paymentMethods,
            isSignedIn,
            customer: { addresses = [] },
        } = props;

        toggleBreadcrumbs(false);

        const isAddressesEmpty = isSignedIn && addresses.length === 0;

        this.state = {
            isLoading: !!is_virtual,
            isDeliveryOptionsLoading: false,
            requestsSent: 0,
            paymentMethods: [],
            shippingMethods: [],
            shippingAddress: {},
            currentDeliveryAddress: {},
            billingAddress: undefined,
            checkoutStep: this.determineCheckoutStepFromUrl(),
            orderID: '',
            paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || undefined,
            checkoutItems: {},
            email: savedEmail || '',
            isGuestEmailSaved: false,
            isCreateUser: false,
            estimateAddress: undefined,
            isPickInStoreMethodSelected: false,
            isVisibleEmailRequired: false,
            selectedStoreAddress: undefined,
            password: '',
            selectedCustomerAddressId: 0,
            isSubmitted: false,
            selectedShippingMethod: undefined,
            // isSameAsShipping: isAddressesEmpty || !isSignedIn,
            isSameAsShipping: true,
            isMounted: false,
            prevPaymentMethods: paymentMethods,
            paymentMethod: '',
            isOrderButtonEnabled: true,
            selectedPaymentMethod: '',
            tabbyErrorMessage: '',
            isTabbyDisabled: false,
        };

        // this.checkIsCartActive();

        const script = document.createElement('script');
        script.src = `https://cdn.tamara.co/widget/installment-plan.min.js`;
        script.async = true;

        document.body.appendChild(script);
    }

    componentDidMount() {
        const {
            guest_checkout,
            updateMeta,
            isGuestNotAllowDownloadable,
            totals,
        } = this.props;

        const {
            shippingMethods,
            email,
        } = this.state;

        this.handleRedirectIfNoItemsInCart();
        this.handleRedirectIfLessThanMinAmountInCart();

        // if guest checkout is disabled and user is not logged in => throw him to homepage
        if (!guest_checkout && !isSignedIn()) {
            history.push(appendWithStoreCode('/'));
        }

        // if guest is not allowed to checkout with downloadable => redirect to login page
        if (!isSignedIn() && isGuestNotAllowDownloadable) {
            this.handleRedirectIfDownloadableInCart();
        }

        if (email) {
            // console.log("calling checkEmailAvailability in componentdidmount: ", email);
            this.checkEmailAvailability(email);
        }

        // const previousShippingMethod = shippingMethods.find(
        //     (method) => `${method.carrier_code}_${method.method_code}` === savedShippingMethodCode,
        // );

        const [defaultShippingMethod] = shippingMethods.filter((method) => method.available);
        const selectedShippingMethod = defaultShippingMethod || {};

        // console.log("componentDidMount selectedShippingMethod", selectedShippingMethod);
        
        this.setState( { selectedShippingMethod });

        if(totals.email != null) {
            this.setState( { email: totals.email});
        }
        
        updateMeta({ title: __('OnepageCheckout') });
    }

    componentDidUpdate(
        prevProps,
        prevState,
    ) {
        const {
            match: {
                params: {
                    step: urlStep = '',
                },
            },
            isEmailAvailable,
            updateEmail,
            isCartLoading,
            shippingFields,
            shippingFields: {
                shipping_method,
            },
            savedShippingMethodCode,
            totals,
            totals: {
                is_virtual,
            },
            showInfoNotification,
            savedEmail,
        } = this.props;

        const {
            match: {
                params: {
                    step: prevUrlStep = '',
                },
            },
            isCartLoading: prevIsCartLoading,
        } = prevProps;

        this.setState( { email: savedEmail || totals.email });

        const { email, checkoutStep, isVisibleEmailRequired, orderID, currentDeliveryAddress, selectedPaymentMethod } = this.state;
        const { email: prevEmail, isVisibleEmailRequired: prevIsVisibleEmailRequired, currentDeliveryAddress: prevDeliveryAddress } = prevState;
        const { location: { pathname = '' } } = history;
        if (!orderID) {
            this.handleRedirectIfNoItemsInCart();
            this.handleRedirectIfLessThanMinAmountInCart();
        }

        if (prevIsCartLoading && !isCartLoading) {
            if (checkoutStep === CheckoutSteps.CHECKOUT_STEP) {
                if(is_virtual) {
                    this._getPaymentMethods();
                }
            }
            
            this.saveShippingFieldsAsShippingAddress(shippingFields, !!is_virtual);
        }
        if (!isEmailAvailable) {
            updateEmail(email);
        }

        if(currentDeliveryAddress.telephone != prevDeliveryAddress.telephone) {
            // console.log("currentDeliveryAddress telephone: ", currentDeliveryAddress.telephone);
            this.setState({ 
                isTabbyDisabled: false,
                tabbyErrorMessage: '',
             });

            if (selectedPaymentMethod == TABBY_PAYMENT) {
                this.setState({ 
                    selectedPaymentMethod: '',
                    paymentMethod: '',
                });
            }
        }

        // Shipping step lines
        const { isPickInStoreMethodSelected } = this.state;
        const { isPickInStoreMethodSelected: prevIsPickInStoreMethodSelected } = prevProps;

        if (isPickInStoreMethodSelected !== prevIsPickInStoreMethodSelected) {
            if (isPickInStoreMethodSelected) {
                scrollToTop();
                this.onShippingMethodSelect(this.returnInStorePickupMethod());
            } else if (prevIsPickInStoreMethodSelected) {
                this.resetShippingMethod();
            }
        }

        if (urlStep.includes(
            CheckoutUrlSteps.CHECKOUT_URL_STEP,
        ) && prevUrlStep.includes(
            CheckoutUrlSteps.DETAILS_URL_STEP,
        )) {
            BrowserDatabase.deleteItem(PAYMENT_TOTALS);
            history.push(appendWithStoreCode(CART_URL));
        }

        if (email !== prevEmail) {
            this.checkEmailAvailability(email);

            if (email && isVisibleEmailRequired !== prevIsVisibleEmailRequired) {
                this.onChangeEmailRequired();
            }
        }   
        
        // Billing Step lines
        const { customer: { default_billing, default_shipping }, newShippingId } = this.props;
        // const { isMounted, isSameAsShipping: currIsSameAsShipping } = this.state;
        // const { isSameAsShipping: prevIsSameAsShipping } = prevState;
        // const isSameAsShipping = this.isSameShippingAddress({ default_billing, default_shipping });

        // default billing & shipping are undefined on initial mount
        // wait until they become assigned to real values
        // then check for isSameAsShipping condition
        
        // if (!isMounted && default_billing) {
        //     this.setState({ isSameAsShipping, isMounted: true });
        // }

        // if (currIsSameAsShipping) {
        //     this.onAddressSelect(
        //         // if the user selected a shipping address different from default
        //         newShippingId > 0 ? newShippingId : Number(default_shipping),
        //     );
        // }
    }

    componentWillUnmount()
     {
        const { toggleBreadcrumbs, setPickUpStore } = this.props;

        toggleBreadcrumbs(true);
        setPickUpStore(null);
    }

    saveShippingFieldsAsShippingAddress(address, is_virtual) {
        const {
            street_0,
            street_1,
            street_2,
            shipping_method,
            ...data
        } = address;
        const { savedEmail } = this.props;
        const { checkoutStep } = this.state;
        // console.log("saveShippingFieldsAsShippingAddress is_virtual", is_virtual);
        const checkoutData = (
            is_virtual
                ? { shippingAddress: {}, isGuestEmailSaved: false }
                : {
                    shippingAddress: data,
                    isGuestEmailSaved: savedEmail ? true : false,
                }
        );

        this.setState({
            ...checkoutData,
            email: savedEmail,
        });
    }

    onEmailChange(email) {
        // console.log("Email changed: ", email);
        const {
            paymentMethod,
            selectedPaymentMethod,
        } = this.state;

        this.setState({ 
            email,
            isTabbyDisabled: false,
            tabbyErrorMessage: '',
        });

        if (selectedPaymentMethod == TABBY_PAYMENT) {
            this.setState({ 
                selectedPaymentMethod: '',
                paymentMethod: '',
            });
        }
    }

    onCreateUserChange() {
        const { isCreateUser } = this.state;

        this.setState({ isCreateUser: !isCreateUser });
    }

    onPasswordChange(password) {
        this.setState({ password });
    }

    onShippingMethodSelect(selectedShippingMethod) {
        // const { method_code } = selectedShippingMethod;
        const {
            totals,
        } = this.props;

        this.setState({ selectedShippingMethod });

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code,
        } = selectedShippingMethod || {};
        
        const { shippingAddress } = this.state;

        // console.log("onShippingMethodSelect SHipping address :", shipping_carrier_code);
        const data = {
            billing_address: shippingAddress,
            shipping_address: shippingAddress,
            shipping_carrier_code: shipping_carrier_code || '',
            shipping_method_code: shipping_method_code || '',
        };
        this.saveAddressInformation(data);

        this.gtmShippingStep(totals, shippingAddress, shipping_carrier_code);
    }

    onShippingEstimationFieldsChange(address) {
        const cartId = getCartId();

        // console.log("onShippingEstimationFieldsChange address", address);
        if (!cartId) {
            return;
        }

        this.handleFetchEstimateShippingCosts({ address, cartId });
    }

    determineCheckoutStepFromUrl() {
        const {
            match: {
                params: {
                    step: urlStep = '',
                },
            },
            totals: {
                is_virtual,
            },
        } = this.props;
        const { location: { pathname = '' } } = history;
        // console.log("determineCheckoutStepFromUrl Step: ", urlStep);
        if (urlStep.includes(CheckoutUrlSteps.DETAILS_URL_STEP)) {
            return CheckoutSteps.DETAILS_STEP;
        }

        if (urlStep.includes(CheckoutUrlSteps.PAYMENT_URL_STEP)) {
            return CheckoutSteps.PAYMENT_STEP;
        }

        this._getPaymentMethods();

        return CheckoutSteps.CHECKOUT_STEP;
    }

    async checkIsCartActive() {
        const {
            showErrorNotification
        } = this.props;
        try {
            const cartId = getCartId();

            let cartItems = await fetchQuery(
                CartQuery.getCartQuery(
                    cartId || '',
                ), 
            );
            // console.log("cartItems.cartData: ", cartItems.cartData);
            if (cartItems.cartData.total_quantity <= 0) {
                window.location.reload();
                return;
            }
        } catch (error) {
            showErrorNotification(__(getErrorMessage(error)));
            window.location.reload();
            return;
        }
        
    }

    async handleRedirectIfNoItemsInCart() {
        const {
            totals: {
                items = [],
            },
            isCartLoading,
            showInfoNotification,
        } = this.props;

        const { checkoutStep, orderID } = this.state;
        if (
            (
                (!isCartLoading && !items.length && checkoutStep != CheckoutSteps.PAYMENT_STEP) ||
                (checkoutStep === CheckoutSteps.DETAILS_STEP && !orderID) || 
                (checkoutStep === CheckoutSteps.PAYMENT_STEP && !orderID)
            ) 
        ) {
            
            if (checkoutStep !== CheckoutSteps.DETAILS_STEP) {
                showInfoNotification(__('Please add at least one product to cart!'));
            }
            
            if (!(orderID && checkoutStep === CheckoutSteps.DETAILS_STEP)) {
                history.push(appendWithStoreCode(CART_URL));
            }
        }
    }

    handleRedirectIfLessThanMinAmountInCart() {
        const {
            minimumOrderAmount: { minimum_order_amount_reached = true } = {},
        } = this.props;

        const { checkoutStep } = this.state;

        if (!minimum_order_amount_reached && checkoutStep !== CheckoutSteps.DETAILS_STEP && checkoutStep !== CheckoutSteps.PAYMENT_STEP) {
            // console.log("MinAMountCart checkoutStep: ", checkoutStep);
            history.push(appendWithStoreCode(CART_URL));
        }
    }

    handleRedirectIfDownloadableInCart() {
        const { totals: { items }, showInfoNotification } = this.props;

        const isDownloadable = items?.find(({ product }) => product.type_id === ProductType.DOWNLOADABLE);

        if (!isDownloadable) {
            return;
        }

        showInfoNotification(__('Please sign in or remove downloadable products from cart!'));
        history.replace(appendWithStoreCode(AccountPageUrl.LOGIN_URL));
    }

    handleSelectDeliveryMethod() {
        const { isPickInStoreMethodSelected } = this.state;

        this.setState({ isPickInStoreMethodSelected: !isPickInStoreMethodSelected });
    }

    onStoreSelect(address) {
        this.setState({ selectedStoreAddress: address });
    }

    onChangeEmailRequired() {
        const { email } = this.state;

        this.setState({ isVisibleEmailRequired: !email });
    }

    onOnepageCheckoutPageError(
        form,
        fields,
        validation,
    ) {
        // TODO: implement notification if some data in Form can not display error
        const { isSubmitted } = this.state;

        this.onChangeEmailRequired();
        this.setState({ isSubmitted: !isSubmitted });
        scrollToError(fields, validation);
    }

    onOnepageCheckoutPageSuccess(
        form,
        fields,
    ) {
        const {
            updateShippingFields,
            addressLinesQty,
            customer: { default_shipping },
            customer,
            totals,
            currentStore,
        } = this.props;

        const {
            selectedCustomerAddressId,
            selectedShippingMethod,
            selectedStoreAddress,
            currentDeliveryAddress,
            email,
        } = this.state;

        this.setState({ isLoading: true });

        const formattedFields = transformToNameValuePair(fields);

        // Joins streets into one variable
        if (addressLinesQty > 1) {
            formattedFields.street = [];
            // eslint-disable-next-line fp/no-loops,fp/no-let
            for (let i = 0; i < addressLinesQty; i++) {
                if (formattedFields[ `street_${i}`]) {
                    (formattedFields.street).push(
                        formattedFields[ `street_${i}`],
                    );
                }
            }
        }

        // console.log("onOnepageCheckoutPageSuccess formattedFields: ", formattedFields);

        let formFields = trimCheckoutAddress(formattedFields);
        
        formFields.telephone = currentDeliveryAddress.telephone;

        const shippingAddress = selectedCustomerAddressId
            ? this._getAddressById(selectedCustomerAddressId)
            : formFields;

        if (!shippingAddress) {
            return;
        }

        if (shippingAddress.region_id == "" || shippingAddress.region_id == null || shippingAddress.region_id == undefined) {
            shippingAddress.region_id = 0;
        }

        // console.log("onOnepageCheckoutPageSuccess shippingAddress: ", shippingAddress);
        // console.log("onOnepageCheckoutPageSuccess selectedShippingMethod: ", selectedShippingMethod);
        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code,
        } = selectedShippingMethod || {};

        const isInStoreDelivery = Object.keys(selectedStoreAddress || {}).length > 0;

        const data = {
            billing_address: isInStoreDelivery ? this.getStoreAddress(shippingAddress, true) : shippingAddress,
            shipping_address: isInStoreDelivery ? this.getStoreAddress(shippingAddress) : shippingAddress,
            shipping_carrier_code: shipping_carrier_code || '',
            shipping_method_code: shipping_method_code || '',
        };

        // console.log("OnCheckout Success address: ", data);
        this.setState({ shippingAddress: data.shipping_address });

        this.saveAddressInformation(data, false);
        const shipping_method = `${shipping_carrier_code}_${shipping_method_code}`;
        const { street = [] } = formattedFields;

        updateShippingFields({
            ...(
                street.length
                    || ('id' in data.shipping_address
                    && default_shipping
                    && parseInt(default_shipping, 10) === data.shipping_address.id)
                    ? formattedFields : data.shipping_address
            ),
            shipping_method,
        });

        const paymentMethodSelected = this._getPaymentData(formattedFields);

        // console.log("onOnepageCheckoutPageSuccess paymentMethod: ", paymentMethodSelected);

        // call GTM Checkout Shipping
        this.gtmShippingStep(totals, data.shipping_address, shipping_method);

        // Set Billing Address
        // const formattedFields = transformToNameValuePair(fields);
        // const address = this._getAddress(formattedFields);

        // if (paymentMethodSelected.code == HYPERPAY_CARDS || paymentMethodSelected.code == HYPERPAY_APPLEPAY) {
        //     this.onOnepageCheckoutProceedPayment(paymentMethodSelected.code, data.shipping_address);
        // } else {
            console.log("currentStore: ", currentStore);
            



            this.onChangeEmailRequired();
            
            if(!isSignedIn()) {
                // this.gtmGuestUserSignup();
                // this.gtmGuestUserSignin();
                const weUser = {
                    email: email, 
                    firstname: data.shipping_address.firstname, 
                    lastname: data.shipping_address.lastname,
                    phone: "+"+data.shipping_address.telephone, 
                    cart_value: totals.prices.grand_total.value
                }

                weUser['Language'] = currentStore === 'ar-sa' ? 'Arabic' : 'English';
                weUser['Registered_on'] = new Date();

                agtm.triggerAuthDLEvent('WeSignIn', weUser);
                agtm.triggerAuthDLEvent('WeSignup', weUser);
            } else {
                agtm.triggerUserDataEvent({email: customer.email, firstname: customer.firstname, lastname: customer.lastname, phone: "+"+data.shipping_address.telephone, cart_value: totals.prices.grand_total.value});
            }
            

            this.savePaymentInformation({
                billing_address: shippingAddress,
                paymentMethod: paymentMethodSelected,
            });
        // }
    }

    selectedDeliveryAddress(address) {
        // console.log("selectedDeliveryAddress", address);
        const {tele_ext_code: phoneExt = '', telephone: phoneNumber = ''} = address;
        let extCode = phoneExt.split("+");
        if (phoneNumber == "undefined") {
            phoneNumber = "";
        }
        address.telephone = extCode[1] + phoneNumber;
        if (address.telephone == "undefined") {
            address.telephone = "";
        }
        
        this.setState({ 
            shippingAddress: address,
            currentDeliveryAddress: address,
        });
    }

    gtmShippingStep(orderData, shippingAddress, shippingMethod) {
        // console.log("OrderData: ", orderData);
        // console.log("ShippingAddress: ", shippingAddress);
        // console.log("ShippingMethod: ", shippingMethod);
        const {
            email,
            paymentTotals,
        } = this.state;

        let cartTotalss = BrowserDatabase.getItem(CART_TOTALS);

        const gtmObj = {
            customerInfo: { email: email},
            shipping: shippingAddress,
            shipping_method: shippingMethod,
            summary: cartTotalss.prices,
            products: cartTotalss.items,
        };
        var date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = 'Add Shipping Info'
        let contentName = 'Add Shipping Info'
        let contentIds = []
        let contents = []
        for (let i in cartTotalss.items) {
            contentIds.push(has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id)
            contents.push({ id: has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id, quantity: cartTotalss.items[i].quantity ? cartTotalss.items[i].quantity : 1 })
        }
        agtm.checkoutEvent(date_time, gtmObj, 'shipping', 'CheckoutPage', 'en', { date_time, contentIds, contents, contentName, contentCategory });
    }

    gtmPaymentStarted(paymentMethod, address = undefined) {
        const {
            totals,
        } = this.props;

        const {
            email,
            shippingAddress,
            selectedPaymentMethod,
            selectedShippingMethod,
            paymentTotals,
        } = this.state;

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code,
        } = selectedShippingMethod || {};

        let cartTotalss = BrowserDatabase.getItem(CART_TOTALS);

        const shipping_method = `${shipping_carrier_code}_${shipping_method_code}`;

        const selectedPMethod = !isEmpty(selectedPaymentMethod) ? selectedPaymentMethod : isSet(paymentMethod.code) ? paymentMethod.code : paymentMethod;
        // console.log("totals--: ", totals);
        // console.log("paymentMethod--: ", paymentMethod);
        // console.log("selectedPaymentMethod--: ", selectedPMethod);
        // console.log("paymentTotals--: ", paymentTotals);
        // console.log("shippingAddress--: ", shippingAddress);

        const gtmObj = {
            customerInfo: { email: email},
            payment: selectedPMethod,
            shipping_method: shipping_method,
            shipping: address || shippingAddress,
            summary: cartTotalss.prices,
            products: cartTotalss.items,
        };
        var date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = 'Add Payment Info'
        let contentName = 'Add Payment Info'
        let contentIds = []
        let contents = []
        for (let i in cartTotalss.items) {
            contentIds.push(has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id)
            contents.push({ id: has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id, quantity: cartTotalss.items[i].quantity ? cartTotalss.items[i].quantity : 1 })
        }
        agtm.checkoutEvent(date_time, gtmObj, 'payment', 'CheckoutPage', 'en', { date_time, contentIds, contents, contentName, contentCategory });
    }

    gtmPaymentMethodSelected(paymentMethod, address = undefined) {
        const {
            totals,
        } = this.props;

        const {
            email,
            shippingAddress,
            selectedPaymentMethod,
            selectedShippingMethod,
            paymentTotals,
        } = this.state;

        let cartTotalss = BrowserDatabase.getItem(CART_TOTALS);

        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code,
        } = selectedShippingMethod || {};

        const shipping_method = `${shipping_carrier_code}_${shipping_method_code}`;

        const selectedPMethod = !isEmpty(selectedPaymentMethod) ? selectedPaymentMethod : has(paymentMethod, "code") ? paymentMethod.code : paymentMethod;
        // console.log("payment_selected totals--: ", totals);
        // console.log("paymentMethod--: ", paymentMethod);
        // console.log("selectedPaymentMethod--: ", selectedPaymentMethod);
        // console.log("cartTotalss--: ", cartTotalss);
        // console.log("paymentTotals--: ", paymentTotals);
        // console.log("gtmPaymentMethodSelected shippingAddress--: ", shippingAddress);

        const gtmObj = {
            customerInfo: { email: email},
            payment: selectedPMethod,
            shipping_method: shipping_method,
            shipping: address || shippingAddress,
            summary: cartTotalss.prices,
            products: cartTotalss.items,
        };
        var date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = 'Add Payment Info'
        let contentName = 'Add Payment Info'
        let contentIds = []
        let contents = []
        for (let i in cartTotalss.items) {
            contentIds.push(has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id)
            contents.push({ id: has(cartTotalss.items[i], 'sku') ? cartTotalss.items[i].sku : cartTotalss.items[i].product.id, quantity: cartTotalss.items[i].quantity ? cartTotalss.items[i].quantity : 1 })
        }
        agtm.checkoutEvent(date_time, gtmObj, 'payment_selected', 'CheckoutPage', 'en', { date_time, contentIds, contents, contentName, contentCategory });
    }

    isSameShippingAddress({
        default_shipping,
        default_billing,
    }) {
        const {
            totals: { is_virtual },
            selectedShippingMethod,
            newShippingId,
        } = this.props;

        if (is_virtual) {
            return false;
        }

        if (selectedShippingMethod && selectedShippingMethod.method_code === StoreInPickUpCode.METHOD_CODE) {
            return false;
        }

        // if the user selected a shipping address different from default
        if (newShippingId > 0) {
            return newShippingId === parseInt(default_billing || '0', 10);
        }

        // otherwise use the default values
        return default_shipping === default_billing;
    }

    onAddressSelect(id) {
        
        if (!id || id == 0 ) {
            this.setState({ 
                selectedCustomerAddressId: 0
            });
            return null;
        }
        const shippingAddresss = this._getAddressById(id);
        this.setState({ 
            selectedCustomerAddressId: id,
            shippingAddress: shippingAddresss,
            currentDeliveryAddress: shippingAddresss,
        });
    }

    onSameAsShippingChange() {
        // this.setState(({ isSameAsShipping }) => ({ isSameAsShipping: !isSameAsShipping }));
    }

    async onPaymentMethodSelect(code = '') {
        
        this.setState({ 
            isLoading: true,
            paymentMethod: code,
            selectedPaymentMethod: code,
        });
        const cart_id = getCartId();

        if (!cart_id || code == '') {
            return;
        }

        const {
            email,
            currentDeliveryAddress,
        } = this.state;

        const preScoringPayload = {
            cart_id: cart_id,
            email,
            phone: currentDeliveryAddress.telephone,
            city: currentDeliveryAddress.city,
            street: '',
        }
        
        // console.log("onPaymentMethodSelect code: ", code);
        // Call set Payment method mutation
        await fetchMutation(CheckoutQuery.applyPaymentMethodOnCheckoutMutation({
            cart_id,
            payment_method: {
                code,
            },
        }, cart_id)).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/setPaymentMethodOnCheckout/fetchMutation/then */
            async ({ setPaymentMethodOnCheckout: data }) => {
                const { totals } = data;

                BrowserDatabase.setItem(
                    totals,
                    PAYMENT_TOTALS,
                    ONE_MONTH_IN_SECONDS,
                );

                const { cartData: cartTotals = {}}  = await fetchQuery(
                    CartQuery.getCartQuery(
                        cart_id,
                    ),
                );

                BrowserDatabase.setItem(
                    cartTotals,
                    CART_TOTALS,
                );

                this.setState({
                    paymentTotals: totals,
                    checkoutItems: cartTotals,
                });


                this.gtmPaymentMethodSelected(code);
            },
            this._handleError,
        );

        // Check if it is Tabby then, is it available for address?
        if(code == TABBY_PAYMENT) {
            const query = TabbyQuery.checkTabbyPreScoring(preScoringPayload);
            await executePost(prepareQuery([query])).then(
                ( data ) => {
                    // console.log("==checktabbyprescoring==: ", data);
    
                    this.setState({
                        tabbyErrorMessage: data.checktabbyprescoring.message,
                        isTabbyDisabled: !data.checktabbyprescoring.success,
                    });
                }
            );
        }

        this.setState({
            isLoading: false,
        });

    }

    showPopup() {
        const { showPopup, termsAndConditions } = this.props;
        const {
            name: title = __('Terms and Conditions'),
            content: text = __('There are no Terms and Conditions configured.'),
        } = termsAndConditions[ 0 ] || {};

        return showPopup({
            title, text,
        });
    }

    _getPaymentData(fields) {
        const { paymentMethod: code } = this.state;

        switch (code) {
        case PaymentMethods.PURCHASE_ORDER:
            const { purchaseOrderNumber } = fields;

            return {
                code,
                purchase_order_number: purchaseOrderNumber,
            };

        default:
            return { code };
        }
    }

    getBillingSameAsShipping() {
        const { selectedShippingMethod, shippingAddress } = this.props;

        if (selectedShippingMethod && selectedShippingMethod.method_code === StoreInPickUpCode.METHOD_CODE) {
            const { extension_attributes, ...billingAddress } = shippingAddress || {};

            return billingAddress;
        }

        return shippingAddress || {};
    }

    _getAddress(fields) {
        const { addressLinesQty } = this.props;

        const {
            isSameAsShipping,
            selectedCustomerAddressId,
        } = this.state;

        const formFields = getFormFields(fields, addressLinesQty);

        if (isSameAsShipping) {
            return this.getBillingSameAsShipping();
        }

        if (!selectedCustomerAddressId) {
            const joinedStreetAddressFields = setAddressesInFormObject(formFields, addressLinesQty, 'street_');

            return trimCheckoutAddress(joinedStreetAddressFields);
        }

        const { customer: { addresses } } = this.props;
        const address = addresses?.find(({ id }) => id === selectedCustomerAddressId);

        return {
            ...trimCheckoutCustomerAddress(address),
            save_in_address_book: false,
        };
    }

    returnInStorePickupMethod() {
        const { shippingMethods = [] } = this.state;

        return shippingMethods.find((el) => el?.method_code === StoreInPickUpCode.METHOD_CODE);
    }

    resetShippingMethod() {
        this.onShippingMethodSelect({ method_code: '' });
    }

    getStoreAddress(
        shippingAddress,
        isBillingAddress = false,
    ) {
        const {
            selectedStoreAddress: {
                region,
                city,
                postcode,
                phone,
                street,
                name,
                pickup_location_code,
                country_id,
            } = {},
        } = this.props;

        const storeAddress = {
            ...shippingAddress,
            country_id,
            region,
            city,
            postcode,
            telephone: phone,
            street,
            firstname: name,
            lastname: 'Store',
        };

        if (isBillingAddress) {
            return storeAddress;
        }

        return {
            ...storeAddress,
            extension_attributes: [
                {
                    attribute_code: StoreInPickUpCode.ATTRIBUTE_CODE,
                    value: pickup_location_code || '',
                },
            ],
        };
    }

    // onAddressSelect(id) {
    //     this.setState({ selectedCustomerAddressId: id });
    // }

    // onShippingMethodSelect(method) {

    //     if (!method) {
    //         return;
    //     }

    //     this.setState({ selectedShippingMethod: method });
    //     // this.onShippingMethodSelect(method);
    // }

    _getAddressById(addressId) {
        const { customer: { addresses } } = this.props;

        const address = (addresses)?.find(({ id }) => id === addressId);

        if (!address) {
            return null;
        }

        return {
            ...trimCheckoutCustomerAddress(address),
            save_in_address_book: false,
            id: addressId,
        };
    }

    goBack() {
        const { checkoutStep } = this.state;

        history.goBack();
    }

    setDetailsStep(orderID) {
        const { resetCart, resetGuestCart, setNavigationState } = this.props;

        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        // if (isSignedIn()) {
        //     resetCart();
        // } else {
        //     resetGuestCart();
        // }

        resetCart(false);

        this.setState({
            isLoading: false,
            paymentTotals: undefined,
            checkoutStep: CheckoutSteps.DETAILS_STEP,
            orderID,
        });
        // console.log("setDetailsStep orderID: ", orderID);
        
        setNavigationState({
            name: NavigationTabsMap.CART_TAB,
        });
    }

    setLoading(isLoading = true) {
        this.setState({ isLoading });
    }

    async setShippingAddress(isDefaultShipping = false) {
        const { shippingAddress } = this.state;
        // console.log("setShippingAddress: ", shippingAddress);
        const { region, region_id, ...address } = shippingAddress || {};

        const mutation = MyAccountQuery.getCreateAddressMutation({
            ...address,
            region: { region, region_id },
            default_shipping: isDefaultShipping,
        });

        const data = await fetchMutation(mutation);

        if (data?.createCustomerAddress) {
            this.setState({
                shippingAddress: {
                    ...shippingAddress,
                    id: data.createCustomerAddress.id,
                },
            });
        }

        return true;
    }

    containerProps() {
        const {
            cartTotalSubPrice,
            isEmailAvailable,
            isMobile,
            setHeaderState,
            totals,
            isInStoreActivated,
            isSignedIn,
            isCartLoading,
            termsAndConditions,
            termsAreEnabled,
            guest_checkout,
        } = this.props;
        const {
            billingAddress,
            checkoutStep,
            email,
            estimateAddress,
            isCreateUser,
            isDeliveryOptionsLoading,
            isGuestEmailSaved,
            isLoading,
            orderID,
            paymentMethods,
            paymentTotals,
            selectedShippingMethod,
            selectedPaymentMethod,
            shippingAddress,
            currentDeliveryAddress,
            shippingMethods,
            selectedStoreAddress,
            isPickInStoreMethodSelected,
            isVisibleEmailRequired,
            isSameAsShipping, 
            paymentMethod,
            tabbyErrorMessage,
            isTabbyDisabled,
        } = this.state;

        return {
            billingAddress,
            cartTotalSubPrice,
            checkoutStep,
            checkoutTotals: this._getCheckoutTotals(),
            email,
            estimateAddress,
            isCreateUser,
            isDeliveryOptionsLoading,
            isEmailAvailable,
            isGuestEmailSaved,
            isInStoreActivated,
            isSignedIn,
            termsAndConditions,
            termsAreEnabled,
            guest_checkout,
            isLoading,
            isMobile,
            orderID,
            paymentMethods,
            paymentTotals,
            selectedShippingMethod,
            selectedPaymentMethod,
            setHeaderState,
            shippingAddress,
            currentDeliveryAddress,
            shippingMethods,
            totals,
            selectedStoreAddress,
            isPickInStoreMethodSelected,
            isCartLoading,
            isVisibleEmailRequired,
            isSameAsShipping, 
            paymentMethod,
            tabbyErrorMessage,
            isTabbyDisabled,
        };
    }

    _handleError(error) {
        const { showErrorNotification } = this.props;

        this.setState({
            isDeliveryOptionsLoading: false,
            isLoading: false,
        }, () => {
            showErrorNotification(getErrorMessage(error));
        });

        return false;
    }

    _getPaymentMethods() {
        const cartId = getCartId();

        if (!cartId) {
            return;
        }

        fetchQuery(CheckoutQuery.getPaymentMethodsQuery(
            cartId,
        )).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/_getPaymentMethods/fetchQuery/then */
            ({ getPaymentMethods: paymentMethods }) => {
                this.setState({ isLoading: false, paymentMethods });
            },
            this._handleError,
        );
    }

    _getCheckoutTotals() {
        const { totals: cartTotals } = this.props;
        const { paymentTotals: { shipping_amount = 0 , cod_fee_incl_tax = 0} = {}, checkoutItems, paymentMethod } = this.state;

        let finalTotals =  cartTotals;
        if (shipping_amount) {
            finalTotals = { ...cartTotals, shipping_amount };
        }

        if (cod_fee_incl_tax > 0) {
            finalTotals = { ...finalTotals, cod_fee_incl_tax };
            if (paymentMethod == '') {
                this.onPaymentMethodSelect(HYPERPAY_CARDS);
                this.setState({
                    paymentMethod: '',
                    selectedPaymentMethod: ''
                });
            }
            // this.setState({ paymentMethod: COD_PAYMENT });
        } else {
            // this.setState({ paymentMethod: '' });
        }
        // console.log("getCheckoutTotals before finalTotals: ", finalTotals);
        // console.log("getCheckoutTotals checkoutItems: ", checkoutItems);
        if (Object.keys(checkoutItems).length != 0) {
            let Obj = { 
                items : checkoutItems.items,
            };
            // console.log("getCheckoutTotals Obj: ", Obj);
            finalTotals = Object.assign({}, finalTotals, Obj);
            // console.log("getCheckoutTotals finalTotals: ", finalTotals);
        }
        

        return finalTotals;
    }

    saveGuestEmail() {
        const { email } = this.state;
        const { updateEmail } = this.props;
        const guestCartId = getCartId();

        if (!email) {
            this.setState({ isVisibleEmailRequired: false }, this.onChangeEmailRequired);
        }

        if (!guestCartId || !email) {
            return null;
        }

        const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

        updateEmail(email);

        return fetchMutation(mutation).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/saveGuestEmail/fetchMutation/then */
            ({ setGuestEmailOnCart: data }) => {
                if (data) {
                    this.setState({ isGuestEmailSaved: true });
                }

                return data;
            },
            this._handleError,
        );
    }

    async createUserOrSaveGuest() {
        const {
            createAccount,
            totals: { is_virtual },
            showSuccessNotification,
            isEmailAvailable,
        } = this.props;

        const {
            email,
            password,
            isCreateUser,
            shippingAddress: {
                firstname,
                lastname,
            } = {},
        } = this.state;

        if (!isCreateUser || !isEmailAvailable) {
            return this.saveGuestEmail();
        }

        const options = {
            customer: {
                email,
                firstname,
                lastname,
            },
            password,
        };

        const creation = await createAccount(options);

        if (!creation) {
            return creation;
        }

        showSuccessNotification(__('Your account has been created successfully!'));

        if (!is_virtual) {
            return this.setShippingAddress(true);
        }

        return true;
    }

    async onOnepageCheckoutProceedPayment(code, orderId) {
        const {
            paymentMethod,
        } = this.state;
        
        this.setState({ 
            isLoading: false,
            orderID: orderId,
            checkoutStep: CheckoutSteps.PAYMENT_STEP, 
            selectedPaymentMethod: paymentMethod || code,
        });

        const { resetCart, resetGuestCart, setNavigationState } = this.props;

        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        await resetCart(true);

        // history.replace(appendWithStoreCode(CheckoutStepUrl.CHECKOUT_PAYMENT_URL));
    }

    prepareAddressInformation(addressInformation) {
        const {
            shipping_address,
            billing_address,
            ...data
        } = addressInformation;

        if ('save_in_address_book' in shipping_address && 'save_in_address_book' in billing_address) {
            const {
                id,
                save_in_address_book,
                ...shippingAddress
            } = shipping_address;
            const {
                id: dropId,
                save_in_address_book: dropSaveInBook,
                ...billingAddress
            } = billing_address;

            return {
                ...data,
                shipping_address: shippingAddress,
                billing_address: billingAddress,
            };
        }

        return {
            ...data,
            shipping_address,
            billing_address,
        };
    }

    async saveAddressInformation(addressInformation, processNextSteps = true) {
        const { updateShippingPrice, totals: cartTotals } = this.props;
        const { shipping_address, shipping_method_code } = addressInformation;

        this.setState({
            isLoading: true,
        });

        if (!isSignedIn()) {
            if (!(await this.createUserOrSaveGuest())) {
                // this.setState({ isLoading: false });
                // console.log("Call save shipping address createUserOrSaveGuest: ", addressInformation);
                // return;
            }
        }

        const cartId = getCartId();

        if (!cartId) {
            return;
        }

        fetchMutation(CheckoutQuery.getSaveAddressInformation(
            this.prepareAddressInformation(addressInformation),
            cartId,
        )).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/saveAddressInformation/fetchMutation/then */
            async ({ saveAddressInformation: data }) => {
                if (processNextSteps) {
                    const { payment_methods, totals } = data;

                    updateShippingPrice(totals);
    
                    BrowserDatabase.setItem(
                        totals,
                        PAYMENT_TOTALS,
                        ONE_MONTH_IN_SECONDS,
                    );
    
                    const { cartData: cartTotals = {}}  = await fetchQuery(
                        CartQuery.getCartQuery(
                            cartId,
                        ),
                    );
    
                    BrowserDatabase.setItem(
                        cartTotals,
                        CART_TOTALS,
                    );

                    this.setState({
                        paymentMethods: payment_methods,
                        paymentTotals: totals,
                        checkoutItems: cartTotals,
                    });
                }
               

                this.setState({
                    isLoading: false,
                });
            },
            this._handleError,
        );
    }

    async savePaymentInformation(paymentInformation) {
        const { totals: { is_virtual } } = this.props;
        const {
            billing_address: {
                firstname: billingFirstName,
                lastname: billingLastName,
            },
            billing_address: billingAddress,
        } = paymentInformation;

        /**
          * If cart contains only virtual products then set firstname & lastname
          * from billing step into shippingAddress for user creating.
          */
        if (is_virtual) {
            this.setState({
                shippingAddress: {
                    firstname: billingFirstName,
                    lastname: billingLastName,
                },
            });
        }

        this.setState({ billingAddress });

        if (!isSignedIn()) {
            if (!(await this.createUserOrSaveGuest())) {
                // this.setState({ isLoading: false });

                // return;
            }
        }

        await this.saveBillingAddress(paymentInformation).then(
            /** @namespace Route/Checkout/Container/CheckoutContainer/savePaymentInformation/saveBillingAddress/then */
            () => this.savePaymentMethodAndPlaceOrder(paymentInformation, billingAddress),
            this._handleError,
        );
    }

    trimAddressMagentoStyle(address) {
        const { countries } = this.props;

        const {
            id, // drop this
            country_id,
            region_code, // drop this
            purchaseOrderNumber, // drop this
            region_id,
            region,
            street,
            guest_email,
            ...restOfBillingAddress
        } = address;

        let regionId = region_id;

        if(regionId == "" || regionId == null || regionId == undefined) {
            regionId = 0;
        }

        const newAddress = {
            ...restOfBillingAddress,
            country_code: country_id,
            region,
            region_id: regionId,
            street: removeEmptyStreets(street || ['']),
        };

        /**
          * If there is no region specified, but there is region ID
          * get the region code by the country ID
          */
        if (regionId) {
            // find a country by country ID
            const { available_regions } = countries.find(
                ({ id }) => id === country_id,
            ) || {};

            if (!available_regions) {
                return newAddress;
            }

            // find region by region ID
            const { code } = available_regions.find(
                ({ id }) => +id === +regionId,
            ) || {};

            if (!code) {
                return newAddress;
            }

            newAddress.region = code;
        }

        return newAddress;
    }

    async saveBillingAddress(paymentInformation) {
        const isCustomerSignedIn = isSignedIn();
        const cart_id = getCartId();

        if (!isCustomerSignedIn && !cart_id) {
            return;
        }

        if (!cart_id) {
            return;
        }

        const { billing_address, same_as_shipping } = paymentInformation;
        const {
            shippingAddress: {
                id: shippingAddressId = null,
            } = {},
        } = this.state;
        const billingAddress = {
            address: this.trimAddressMagentoStyle(billing_address),
        };

        if (same_as_shipping && shippingAddressId) {
            billingAddress.customer_address_id = shippingAddressId;
        }

        await fetchMutation(CheckoutQuery.getSetBillingAddressOnCart({
            cart_id,
            billing_address: billingAddress,
        }));
    }

    async savePaymentMethodAndPlaceOrder(paymentInformation, address = undefined) {

        // console.log("savePaymentMethodAndPlaceOrder paymentInformation", paymentInformation);
        const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
        const isCustomerSignedIn = isSignedIn();
        const cart_id = getCartId();

        if (!isCustomerSignedIn && !cart_id) {
            return;
        }

        if (!cart_id) {
            return;
        }

        this.setState({ isLoading: true });
        // console.log("savePaymentMethodAndPlaceOrder cart_id", cart_id);

        // call GTM Payment Started
        this.gtmPaymentStarted(code, address);
        
        try {
            if (code == HYPERPAY_CARDS || code == HYPERPAY_APPLEPAY || code == HYPERPAY_MADA) {
                const orderData = await fetchMutation(TamaraQuery.setPaymentMethodAndPlaceOrderMutation({
                    cart_id,
                    payment_method: {
                        code,
                        purchase_order_number,
                    },
                }));
                // console.log("setPaymentMethodAndPlaceOrderMutation orderData: ", orderData);
                const { setPaymentMethodAndPlaceOrderForTamara: { order: { order_id } } } = orderData;
                this.onOnepageCheckoutProceedPayment(code, order_id);
                
            } else if (code == TAMARA_PAYMENT) {
                const orderData = await fetchMutation(TamaraQuery.setPaymentMethodAndPlaceOrderMutation({
                    cart_id,
                    payment_method: {
                        code,
                        purchase_order_number,
                    },
                }));
                // console.log("setPaymentMethodAndPlaceOrderMutation orderData: ", orderData);
                const { setPaymentMethodAndPlaceOrderForTamara: { order: { order_id } } } = orderData;
                this.placeTamaraOrder(order_id);

            } else if (code == TABBY_PAYMENT) {
                const orderData = await fetchMutation(TabbyQuery.setPaymentMethodAndPlaceOrderMutation({
                    cart_id,
                    payment_method: {
                        code,
                        purchase_order_number,
                    },
                }));
                // console.log("setPaymentMethodAndPlaceOrderMutation orderData: ", orderData);
                const { setPaymentMethodAndPlaceOrderForTabby: { order: { order_id, redirectUrl } } } = orderData;
                this.redirectToTabby(order_id, redirectUrl);

            } else {
                this.setState({ 
                    isLoading: true,
                })
                await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    cart_id,
                    payment_method: {
                        code,
                        [code]: additional_data,
                        purchase_order_number,
                    },
                }));
                this.setState({ 
                    isLoading: true,
                })

                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
                const { placeOrder: { order: { order_id } } } = orderData;
                this.setDetailsStep(order_id);
            }
        } catch (e) {
            this._handleError(e);
        } finally {
            BrowserDatabase.deleteItem(CART_TOTALS);
        }
    }

    async placeTamaraOrder(order_id) {

        const {
            paymentMethod,
        } = this.state;
        
        this.setState({ 
            isLoading: false,
            orderID: order_id,
            selectedPaymentMethod: paymentMethod,
        });

        const { resetCart, resetGuestCart, setNavigationState } = this.props;

                
        const query = TamaraQuery.getTamaraPaymentUrl(order_id);
        executePost(prepareQuery([query])).then(
            /** @namespace AlmusbahblendPwa/Route/Checkout/Container/executeGet/then */
            ( data ) => {
                // console.log("=== getTamaraPaymentUrl : ", data.placetamaraorder);
                this.setState({ 
                    isLoading: false,
                })
        
                // history.push(`${data.placetamaraorder.redirectUrl}`);
                window.location.replace(`${data.placetamaraorder.redirectUrl}`);

                deleteCartId();
                BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                resetCart(true);
            }
        ).catch(
            /** @namespace AlmusbahblendPwa/Route/Checkout/Container/executeGet/then/catch */
            () => {
                this.setState({ isLoading: false })
            }
        );
    }

    async redirectToTabby(order_id, redirect_url) {

        const {
            paymentMethod,
        } = this.state;
        
        this.setState({ 
            isLoading: false,
            orderID: order_id,
            selectedPaymentMethod: paymentMethod,
        });

        const { resetCart, resetGuestCart, setNavigationState } = this.props;

        window.location.replace(`${redirect_url}`);

        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        resetCart(true);
        
    }

    render() {
        return (
             <OnepageCheckout
               { ...this.containerFunctions }
               { ...this.containerProps() }
             />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnepageCheckoutContainer);
